import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import { Link } from 'gatsby'
import Video from "components/video"
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const RestorativeDentistryWhatYouNeedToKnowPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const dental_implant_img = data.dental_implant_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Restorative dentistry: What you need to know</title>
      <meta name="description" content="THE DENTISTS AT GATEWAY CROSSING Extremely caring and friendly service. Front desk has smiling faces and Dr. Vogt understands what it takes to provide exc" />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta property="og:description" content="THE DENTISTS AT GATEWAY CROSSING Extremely caring and friendly service. Front desk has smiling faces and Dr. Vogt understands what it takes to provide exc" />
      <meta property="og:url" content="https://thedentistsatgc.com/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="THE DENTISTS AT GATEWAY CROSSING Extremely caring and friendly service. Front desk has smiling faces and Dr. Vogt understands what it takes to provide exc" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <section id="blog-post-section">
          <Container>
            <div className="col-lg-12">
              <h1>
                Restorative dentistry: What you need to know
              </h1>
              <ul className="list-inline">
                <li className="list-inline-item article-time">
                  2022/04/07
                </li>
                <li className="list-inline-item badge badge-primary">
                Restorative dentistry
                </li>
                <li className="list-inline-item badge badge-secondary">
                  Restorative dentistry: What you need to know
                </li>
              </ul>
                <Img fluid={dental_implant_img} className="blog-post-img"/>
              <h2 className="mb-3">
                Restorative dentistry: What you need to know
              </h2>
              <p>
                If you're like most people, you probably take your teeth for granted. You brush and floss them every day, and maybe visit your dentist for a cleaning once or twice a year if you are diligent about preventive measures. But what would you do if you lost a tooth? Or if one of your teeth was severely damaged? That's where restorative dentistry comes in.
              </p>
              <p>
                This branch of dentistry offers a variety of treatments that can restore your teeth to a point of function. In this blog post, we will discuss the different types of restorative procedures available and what they can do for you. We will also talk about the benefits of restorative dentistry and how it can improve your oral health!
              </p>
              <h3 className="mb-3">
                What is restorative dentistry?
              </h3>
              <p>
                In the simplest of terms, restorative dentistry is any dental treatment that repairs or replaces a tooth. Cavity fillings, dentures, dental crowns, and even dental implants are examples of restorative procedures. Restorative dentistry may aim to restore either the function or appearance of a tooth. Sometimes, it achieves both.
              </p>
              <h3 className="mb-3">
                The benefits of restorative dentistry
              </h3>
              <p>
                There are many benefits to restorative dentistry. Perhaps the most obvious is that it restores your teeth to their former glory. But restorative dentistry can also improve your oral health in other ways.
              </p>
              <p>
              For example, restorative dentistry can help protect your teeth from further damage. It can also prevent tooth decay and gum disease, both of which can lead to serious health problems. In addition, restorative dentistry can make it easier to eat and speak properly.
              </p>
              <p>
              It can also relieve you from pain as well.
              </p>
              <h3 className="mb-3">
                What types of restorative procedures are available?
              </h3>

              <p>There are many different types of restorative procedures available. Some of the most common include:</p>

              <ul>
                
                <li>
                  fillings: These are used to repair small cavities in your teeth.
                </li>

                <li>
                  dentures: Dentures are removable false teeth that can be used to replace one or all of your natural teeth.
                </li>

                <li>
                  bridge: A dental bridge is a dental appliance that is used to replace missing teeth. It is anchored in place by two or more crowns on adjacent teeth.
                </li>
                
                <li>
                  dental crown: A crown is a tooth-shaped cap that is used to cover a damaged or decayed tooth. It can also go on top of a dental implant.
                </li>
                
                <li>
                  root canal: A root canal is a procedure that is used to treat infected or damaged teeth.
                </li>
               
                <li>
                  dental implant: A dental implant is a titanium screw that is implanted in the jawbone. It is placed in the spot of your missing tooth. A dental crown is placed on top to give you full function and restored look.
                </li>
                
                <li>
                  What is the Cost of Dental Implants?
                </li>
              </ul>

              <h3 className="mb-3">
                Which restorative procedure is right for me?
              </h3>
              <p>
                That depends on your individual needs and preferences. Your dentist will be able to recommend the best restorative procedure for you. He or she will take into account the condition of your teeth, your budget, and your lifestyle.
              </p>
              <p>
                If you're not sure which restorative procedure is right for you, ask your dentist for advice. They will be happy to help!
              </p>
              <p>
                Are you considering restorative dentistry? If so, we hope that this blog post helped! For more information, please contact our office. We would be happy to answer any of your questions!
              </p>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default RestorativeDentistryWhatYouNeedToKnowPage

export const query = graphql`
  query RestorativeDentistryWhatYouNeedToKnowPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    dental_implant_img: file(name: { eq: "man-smiling-about-dental-implants" }) {
      childImageSharp {
        fluid(maxWidth: 1130, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
